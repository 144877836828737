<script lang="ts" setup>
import Button from '@cypress-design/vue-button'
import { type ButtonProps } from '@cypress-design/constants-button'
import { hubspotModalInstanceId } from '@/stores'

const props = defineProps<
  {
    instance: string
    class?: string
  } & ButtonProps
>()
</script>

<template>
  <Button v-bind="props" :instance="undefined" @click="hubspotModalInstanceId = instance">
    <slot />
  </Button>
</template>
